.slide-in {
    animation: show 0.3s linear forwards;
}

.slide-out {
    animation: hide 0.1s linear forwards;
}

@keyframes show {
    from {
        top: 0;
    }
    to {
        top: -80px;
    }
}

@keyframes hide {
    from {
        top: -80px;
    }
    to {
        top: 0;
    }
}

.bottom-slide-in {
    animation: bottom-show 0.3s linear forwards;
}

.bottom-slide-out {
    animation: bottom-hide 0.1s linear forwards;
}

@keyframes bottom-show {
    from {
        bottom: 0;
    }
    to {
        bottom: -80px;
    }
}

@keyframes bottom-hide {
    from {
        bottom: -80px;
    }
    to {
        bottom: 0;
    }
}

.page-in {
    animation: page-show 0.15s;
}
.page-out {
    animation: page-hidden 0.15s;
}

@keyframes page-show {
    from {
        transform: translateX(100%);
        visibility: visible;
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes page-hidden {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
        visibility: visible;
    }
}